





import React from "react";
import TeamCard from "./TeamCard";

const Mahendra = () => {
  const MahendraDetails = {
    name: "Mahendra Taur",
    designation: "Director",
    bloodGroup: "A+", // Blood group not mentioned
    profilePic: "images/our-team5/Mahendra.jpg",
    contact: {
      employeeId: "001", // Employee ID not mentioned
      phone: "+919762572714",
      emergencyPhone: "+91 9762572714",
      email: "mahendra@azown.in",
      companyMail: "mahendra@azown.in",
      locationUrl: "https://maps.app.goo.gl/uZSwkEKtCsXLKEuM9",
    },
    website: "https://azown.in",
    address: "Bhumkar Chowk , Pune",
    socialLinks: {
      facebook: "https://www.facebook.com",
      instagram: "https://www.instagram.com",
      twitter: "https://www.twitter.com",
    },
    description: "Mahendra Taur.",
  };

  return <TeamCard {...MahendraDetails} />;
};

export default Mahendra;








