import { Route, Routes } from "react-router-dom";
import AboutUsPage from "../app/components/company/about-us";
import FAQPage from "../app/components/company/faq";
import GalleryPage from "../app/components/company/gallery";
import IconsPage from "../app/components/company/icons";
import PricingPage from "../app/components/company/pricing";
import TeamPage from "../app/components/company/team";
import TestimonialsPage from "../app/components/company/testimonials";
import WhyChooseUsPage from "../app/components/company/why-choose-us";
import MissionVisionPage from "../app/components/IT solutions/mission-vision";
import ServicesPage from "../app/components/IT solutions/services/services";
import ServicesDetailPage from "../app/components/IT solutions/services/detail";
import Portfolio1Page from "../app/components/IT solutions/portfolio/portfolio1";
import Portfolio2Page from "../app/components/IT solutions/portfolio/portfolio2";
import PortfolioDetailPage from "../app/components/IT solutions/portfolio/detail";
import BlogGridPage from "../app/components/IT blogs/blog-grid";
import BlogListSidebarPage from "../app/components/IT blogs/blog-list-sidebar";
import BlogDetailPage from "../app/components/IT blogs/blog-detail";
import BlogDetailSidebarPage from "../app/components/IT blogs/blog-detail-sidebar";
import ContactUsPage from "../app/components/contactus/contact-us";
import Home1Page from "../app/components/home/index";
import Home2Page from "../app/components/home/index2";
import Home3Page from "../app/components/home/index3";
import Webdevlopment from "../app/components/IT solutions/services/Webdevlopment";
import Digitalmarketing from "../app/components/IT solutions/services/Digitalmarketing";
import Videoproduction from "../app/components/IT solutions/services/Videoproduction";
import Graphicdesign from "../app/components/IT solutions/services/Graphicdesign";
import Contentcreation from "../app/components/IT solutions/services/Contentcreation";
import Ecommercesolution from "../app/components/IT solutions/services/Ecommercesolution";
//import Pharma from "../app/components/industries/Pharma";
import Goverment from "../app/components/Goverment/Goverment";
import Entertainmentportfolio from "../app/sections/industries/Entertainmentportfolio";
import Realestateportfolio from "../app/sections/industries/Realestateportfolio";
import ITSolutionsportfolio from "../app/sections/industries/ITSolutionsportfolio";
import Automobileportfolio from "../app/sections/industries/Automobileportfolio";
import Healthcareportfolio from "../app/sections/industries/Healthcareportfolio";
//import CardList from "../app/components/TeamCard/CardList";
import Venkateshchavan from "../app/components/TeamCard/Venkatesh";
import Ajaypatil from "../app/components/TeamCard/Ajaypagtil";
import Prashant from "../app/components/TeamCard/Prashant";
import Radheshyam from "../app/components/TeamCard/Radheshyam";
import Chetan from "../app/components/TeamCard/Chetan";
import Nikita from "../app/components/TeamCard/Nikita";
import Sharvari from "../app/components/TeamCard/Sharvari";
import Samarth from "../app/components/TeamCard/Samarth";
import Akhilesh from "../app/components/TeamCard/Akhilesh";
import Shreya from "../app/components/TeamCard/Shreya";
import Prachi from "../app/components/TeamCard/Prachi";
import Karan from "../app/components/TeamCard/Karan";
import Adinath from "../app/components/TeamCard/Adinath";
import Vishalghodeswar from "../app/components/TeamCard/Vishalghodeswar";
import Rugved from "../app/components/TeamCard/Rugved";
import Mahendra from "../app/components/TeamCard/Mahendra";
import Mukta from "../app/components/TeamCard/Mukta";
import Khushbu from "../app/components/TeamCard/Khushbu";
import Devesh from "../app/components/TeamCard/Devesh";
import  Sejal from '../app/components/TeamCard/Sejal';
import Portfolio from "../app/components/company/Portfolio";
import Pmpml from "../app/components/Goverment/Pmpml";
import Divyangbhavan from "../app/components/Goverment/Divyangbhavan";

function AppRoutes() {
    return (
        <>
            <Routes>
                <Route path="/" element={<Home1Page />} />
                <Route path="/index" element={<Home1Page />} />
                <Route path="/index2" element={<Home2Page />} />
                <Route path="/index3" element={<Home3Page />} />
                <Route path="/about-us" element={<AboutUsPage />} />
                <Route path="/faq" element={<FAQPage />} />
                <Route path="/gallery" element={<GalleryPage />} />
                <Route path="/icons" element={<IconsPage />} />
                <Route path="/testimonials" element={<TestimonialsPage />} />
                <Route path="/why-choose-us" element={<WhyChooseUsPage />} />
                <Route path="/pricing" element={<PricingPage />} />
                <Route path="/team" element={<TeamPage />} />
                <Route path="/mission" element={<MissionVisionPage />} />
                <Route path="/services" element={<ServicesPage />} />
                <Route path="/services/detail" element={<ServicesDetailPage />} />
                <Route path="/portfolio1" element={<Portfolio1Page />} />
                <Route path="/portfolio2" element={<Portfolio2Page />} />
                <Route path="/portfolio/detail" element={<PortfolioDetailPage />} />
                <Route path="/blogs" element={<BlogGridPage />} />
                <Route path="/blogs/list" element={<BlogListSidebarPage />} />
                <Route path="/blogs/detail-with-sidebar" element={<BlogDetailSidebarPage />} />
                <Route path="/blogs/detail" element={<BlogDetailPage />} />
                <Route path="/contact-us" element={<ContactUsPage />} />
                <Route path="/web-devlopment" element={<Webdevlopment/>}/>
                <Route path="/digital-marketing" element={<Digitalmarketing/>}/>
                <Route path="/video-production" element={<Videoproduction/>}/>
                <Route path="/graphic-design" element={<Graphicdesign />} />
                <Route path="/content-creation" element={<Contentcreation/>}/>
                <Route path="/e-commerce-solution" element={<Ecommercesolution/>}/>
                <Route path="/automobile-digital-marketing" element={<Automobileportfolio/>}/>
                <Route path="/government" element={<Goverment />}/>
                <Route path="/entertenment-digital-marketing" element={<Entertainmentportfolio/>}/>
                <Route path="/real-estate-digital-marketing" element={<Realestateportfolio/>}/>
                <Route path="/itsolutions-marketing" element={<ITSolutionsportfolio/>}/>
                <Route path="/healthcare-digital-marketing" element={<Healthcareportfolio/>}/>
                <Route path="/team/venkatesh-chavan" element={<Venkateshchavan />}/>
                <Route path="/team/ajay-patil" element={<Ajaypatil/>}/>
                <Route path="/team/vishal-ghodeswar" element={<Vishalghodeswar/>} />
                <Route path="/team/mahendra-taur" element={<Mahendra/>} />
                <Route path="/team/prashant-chilbile" element={<Prashant/>} />
                <Route path="/team/radheshyam-shiraskar" element={<Radheshyam/>} />
                <Route path="/team/chetan-khote" element={<Chetan/>} />
                <Route path="/team/nikita-amrutkar" element={<Nikita/>} />
                <Route path="/team/sharvari-gharat" element={<Sharvari/>} />
                <Route path="/team/samarth-narawane" element={<Samarth/>} />
                <Route path="/team/akhilesh-mayande" element={<Akhilesh/>} />
                <Route path="/team/shreya-thakur" element={<Shreya/>} />
                <Route path="/team/prachi-pandit" element={<Prachi/>} />
                <Route path="/team/karan-dhole" element={<Karan/>} />
                <Route path="/team/adinath-garud" element={<Adinath/>} />
                <Route path="/team/rugved-deshmukh" element={<Rugved/>} />
                <Route path="/team/mukta-chavan" element={<Mukta/>} />
                <Route path="/team/khushbu-kesari" element={<Khushbu/>}/>
                <Route path="/team/devesh-chavan" element={<Devesh/>} />
                <Route path="/team/sejal-deshmukh" element={<Sejal/>}/>
                <Route path="/portfolio" element={<Portfolio/>} />
                <Route path="/goverment/pmpml" element={<Pmpml/>} />
                <Route path="/goverment/divyangbhavan" element={<Divyangbhavan/>}/>
                
            </Routes>
        </>
    )
}

export default AppRoutes;