



import React from "react";
import TeamCard from "./TeamCard";

const Vishalghodeswar = () => {
  const VishalhDetails = {
    name: "Vishal Ghodeswar",
    designation: "Director",
    bloodGroup: "A+", // Blood group not mentioned
    profilePic: "images/our-team5/vishal.jpg",
    contact: {
      employeeId: "001", // Employee ID not mentioned
      phone: "+918552987685",
      emergencyPhone: "+91 8552987685",
      email: "vishal@azown.in",
      companyMail: "vishal@azown.in",
      locationUrl: "https://maps.app.goo.gl/uZSwkEKtCsXLKEuM9",
    },
    website: "https://azown.in",
    address: "Kokane Chowk, Pune",
    socialLinks: {
      facebook: "https://www.facebook.com",
      instagram: "https://www.instagram.com",
      twitter: "https://www.twitter.com",
    },
    description: "Vishal Ghodeswar.",
  };

  return <TeamCard {...VishalhDetails} />;
};

export default Vishalghodeswar;









