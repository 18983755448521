import React from "react";
import TeamCard from "./TeamCard";

const Devesh = () => {
  const DeveshDetails = {
    name: "Devesh",
    designation: "Photographer",
    bloodGroup: "", // Blood group not mentioned
    profilePic: "images/our-team5/Devesh.jpg",
    contact: {
      employeeId: "010", // Employee ID not mentioned
      phone: "+91 9112299435",
      emergencyPhone: "+91 9876543210", // Emergency phone number
      email: "devesh@azown.in",
      companyMail: "info@azown.in", // Company mail
      locationUrl: "https://maps.app.goo.gl/uZSwkEKtCsXLKEuM9",
    },
    website: "https://azown.in",
    address: "Azown Technology Pvt. Ltd. Pune",
    socialLinks: {
      facebook: "https://www.facebook.com",
      instagram: "https://www.instagram.com",
      twitter: "https://www.twitter.com",
    },
    description: "Devesh is a Director at Azown Technology Pvt. Ltd., leading the team with exceptional vision and expertise in delivering innovative solutions.",
  };
  
 
  

  return <TeamCard {...DeveshDetails} />;
};

export default Devesh;
