import React, { useEffect } from 'react';
import SectionCounter1 from "../../../sections/home/index/section-counter1";
import { loadScript } from "../../../../globals/constants";

function Graphicdesigndetail() {
    useEffect(() => {
        loadScript("js/custom.js");
    }, []);

    return (
        <>
            {/* Service Detail SECTION START */}
            <div className="section-full p-b80 sx-ser-detail-outer sx-bg-white">
                <div className="container">
                    <div className="section-content">
                        <div className="sx-our-service-detail">
                            {/* TITLE START */}
                            <div className="section-head2">
                                <div className="sx-head-s-title">Graphic Design</div>
                                <div className="sx-head-l-title">
                                    <h2 className="sx-title2">Bringing Your Brand to Life Through Design</h2>
                                    <hr style={{ border: '1px solid #ccc', margin: '20px 0' }} />
                                </div>
                            </div>
                            {/* TITLE END */}
                            <div className="sx-our-service-info m-b80">
                                <p>
                                    Visual appeal is crucial in making a memorable impression. Our graphic design services help brands communicate effectively and creatively, delivering designs that capture attention and embody your brand’s identity.
                                </p>
                                <p>
                                    From logo design to complete branding packages, our team of creative designers is here to transform your ideas into visuals that resonate. We specialize in various design solutions, including digital graphics, print materials, and custom illustrations, tailored to fit your unique style and goals.
                                </p>
                                <div className="row sx-about-icon-bx1-column p-t50 p-b20">
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-logo-design" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Logo & Branding</span>
                                                <span className="sx-icon-bx-title-info">Distinctive brand identity</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-graphic" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Print & Digital Design</span>
                                                <span className="sx-icon-bx-title-info">Brochures, flyers, and more</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-illustration" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Custom Illustrations</span>
                                                <span className="sx-icon-bx-title-info">Unique visual storytelling</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p>
                                    Combining aesthetics with strategy, we create designs that not only look great but also serve to achieve your business objectives. Our goal is to produce visuals that help your brand stand out in any medium, leaving a lasting impression.
                                </p>
                            </div>
                            
                            <SectionCounter1 />
                        </div>
                    </div>
                </div>
            </div>
            {/* Service Detail SECTION END */}
        </>
    );
}

export default Graphicdesigndetail;
