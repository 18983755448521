import React, { useEffect } from 'react';
import SectionCounter1 from "../../../sections/home/index/section-counter1";
import { loadScript } from "../../../../globals/constants";

function Contentcreationdetail() {
    useEffect(() => {
        loadScript("js/custom.js");
    }, []);

    return (
        <>
            {/* Service Detail SECTION START */}
            <div className="section-full p-b80 sx-ser-detail-outer sx-bg-white">
                <div className="container">
                    <div className="section-content">
                        <div className="sx-our-service-detail">
                            {/* TITLE START */}
                            <div className="section-head2">
                                <div className="sx-head-s-title">Content Creation</div>
                                <div className="sx-head-l-title">
                                    <h2 className="sx-title2">Crafting Engaging Content That Resonates</h2>
                                    <hr style={{ border: '1px solid #ccc', margin: '20px 0' }} />
                                </div>
                            </div>
                            {/* TITLE END */}
                            <div className="sx-our-service-info m-b80">
                                <p>
                                    In a world driven by digital engagement, content is at the heart of effective communication. Our content creation services are designed to connect with audiences through meaningful, high-quality content that reflects your brand’s voice and vision.
                                </p>
                                <p>
                                    From blog posts and articles to social media content and multimedia, we offer a range of content solutions tailored to your objectives. Our team of writers, designers, and strategists works collaboratively to ensure each piece of content aligns with your brand’s goals and captures your target audience’s attention.
                                </p>
                                <div className="row sx-about-icon-bx1-column p-t50 p-b20">
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-blogging" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Blog & Article Writing</span>
                                                <span className="sx-icon-bx-title-info">Informative and SEO-friendly content</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-social-media" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Social Media Content</span>
                                                <span className="sx-icon-bx-title-info">Engaging posts for all platforms</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-video" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Multimedia Content</span>
                                                <span className="sx-icon-bx-title-info">Videos, graphics, and more</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p>
                                    We combine creativity with data-driven insights to produce content that resonates with your audience and drives engagement. Let us help you tell your story and establish a consistent, impactful presence across all digital platforms.
                                </p>
                            </div>
                            
                            <SectionCounter1 />
                        </div>
                    </div>
                </div>
            </div>
            {/* Service Detail SECTION END */}
        </>
    );
}

export default Contentcreationdetail;
