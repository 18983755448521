

import React from "react";
import TeamCard from "./TeamCard";

const Radheshyam = () => {
  const RadheshyamDetails = {
    name: "Radheshyam Siraskar",
    designation: "Social Media Sr. Executive",
    bloodGroup: "B+", // Blood group not mentioned
    profilePic: "images/our-team5/Radheshyam.jpg",
    contact: {
      employeeId: "003", // Employee ID not mentioned
      phone: "+91 8237387707",
      emergencyPhone: "+91 8788694182",
      email: "radheshyam@azown.in",
      companyMail: "radheshyam@azown.in",
      locationUrl: "https://maps.app.goo.gl/uZSwkEKtCsXLKEuM9",
    },
    website: "https://azown.in",
    address: "Bhujbal Chauk, Wakad, Pune",
    socialLinks: {
      facebook: "https://www.facebook.com",
      instagram: "https://www.instagram.com",
      twitter: "https://www.twitter.com",
    },
    description: "Radheshyam Siraskar is a skilled Social Media Sr. Executive at Azown Technology Pvt. Ltd., bringing creativity and innovation to every project.",
  };

  return <TeamCard {...RadheshyamDetails} />;
};

export default Radheshyam;

