import React, { useEffect } from 'react';
import SectionCounter1 from "../../../sections/home/index/section-counter1";
import { loadScript } from "../../../../globals/constants";

function Digitalmarketingdetail() {
    useEffect(() => {
        loadScript("js/custom.js");
    }, []);

    return (
        <>
            {/* Service Detail SECTION START */}
            <div className="section-full p-b80 sx-ser-detail-outer sx-bg-white">
                <div className="container">
                    <div className="section-content">
                        <div className="sx-our-service-detail">
                            {/* TITLE START */}
                            <div className="section-head2">
                                <div className="sx-head-s-title">Digital Marketing</div>
                                <div className="sx-head-l-title">
                                    <h2 className="sx-title2">Strategies to Enhance Your Digital Reach</h2>
                                    <hr style={{ border: '1px solid #ccc', margin: '20px 0' }} />
                                </div>
                            </div>
                            {/* TITLE END */}
                            <div className="sx-our-service-info m-b80">
                                <p>
                                    In the fast-evolving digital world, an effective digital marketing strategy is essential for reaching and engaging your target audience. Our comprehensive digital marketing services help businesses build a strong online presence and drive meaningful interactions with potential customers.
                                </p>
                                <p>
                                    We specialize in a wide range of digital marketing strategies, including search engine optimization (SEO), social media marketing, content creation, and pay-per-click advertising. Our team of digital marketing experts will work with you to create tailored campaigns that align with your business goals and deliver measurable results.
                                </p>
                                <div className="row sx-about-icon-bx1-column p-t50 p-b20">
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-seo" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">SEO Optimization</span>
                                                <span className="sx-icon-bx-title-info">Boosting your search engine rankings</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-social-media" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Social Media Marketing</span>
                                                <span className="sx-icon-bx-title-info">Engaging your audience on social platforms</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-content" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Content Creation</span>
                                                <span className="sx-icon-bx-title-info">Crafting content that resonates</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p>
                                    Our approach combines creativity, data analysis, and strategic thinking to ensure your brand stands out in the digital space. We continuously monitor and optimize campaigns, ensuring your marketing efforts provide the best possible return on investment.
                                </p>
                            </div>
                            
                            <SectionCounter1 />
                        </div>
                    </div>
                </div>
            </div>
            {/* Service Detail SECTION END */}
        </>
    );
}

export default Digitalmarketingdetail;
