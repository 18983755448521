import React, { useEffect } from 'react';
import SectionCounter1 from "../../../sections/home/index/section-counter1";
import { loadScript } from "../../../../globals/constants";

function Ecommercesolutiondetail() {
    useEffect(() => {
        loadScript("js/custom.js");
    }, []);

    return (
        <>
            {/* Service Detail SECTION START */}
            <div className="section-full p-b80 sx-ser-detail-outer sx-bg-white">
                <div className="container">
                    <div className="section-content">
                        <div className="sx-our-service-detail">
                            {/* TITLE START */}
                            <div className="section-head2">
                                <div className="sx-head-s-title">E-Commerce Solutions</div>
                                <div className="sx-head-l-title">
                                    <h2 className="sx-title2">Building Online Stores That Drive Success</h2>
                                    <hr style={{ border: '1px solid #ccc', margin: '20px 0' }} />
                                </div>
                            </div>
                            {/* TITLE END */}
                            <div className="sx-our-service-info m-b80">
                                <p>
                                    The digital marketplace is rapidly growing, and having a reliable e-commerce solution is essential for success. Our e-commerce services are designed to provide a seamless, user-friendly shopping experience that maximizes sales and customer satisfaction.
                                </p>
                                <p>
                                    From initial design to platform integration, our team specializes in developing customized e-commerce solutions that suit your business needs. We handle everything from secure payment processing to responsive web design, making it easy for your customers to shop on any device.
                                </p>
                                <div className="row sx-about-icon-bx1-column p-t50 p-b20">
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-shopping-cart" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Custom Storefront Design</span>
                                                <span className="sx-icon-bx-title-info">Unique and responsive designs</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-payment" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Secure Payment Processing</span>
                                                <span className="sx-icon-bx-title-info">Fast and reliable transactions</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-seo" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">SEO & Marketing</span>
                                                <span className="sx-icon-bx-title-info">Boosting visibility and sales</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p>
                                    Our e-commerce solutions combine robust functionality with engaging designs to provide customers with an enjoyable shopping experience. Let us help you build an online store that grows your brand and meets the demands of today's digital shoppers.
                                </p>
                            </div>
                            
                            <SectionCounter1 />
                        </div>
                    </div>
                </div>
            </div>
            {/* Service Detail SECTION END */}
        </>
    );
}

export default Ecommercesolutiondetail;
