import React from 'react'
import { NavLink } from "react-router-dom";
import ItodoImage from "../../elements/itodo-img";

const Govermentportfolio = () => {
  return (
    <>
            <div className="section-full p-t110 p-b80 sx-portfolio-outer sx-bg-white">
                <div className="container">
                    {/* TITLE START */}
                    <div className="section-head center">
                        <div className="sx-head-s-title">Our Portfolio</div>
                        <div className="sx-head-l-title">
                            <h2 className="sx-title">Our Projects</h2>
                        </div>
                    </div>
                    {/* TITLE END */}
                    <div className="section-content">
                        <div className="sx-portfolio-bx-wrap m-b30">
                            <ul>
                                <li>
                                    <div className="sx-portfolio-bx">
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-lg-4 col-md-4">
                                                <h4 className="sx-portfolio-title">PMPML</h4>
                                            </div>
                                            <div className="col-lg-2 col-md-4">
                                                <p className="sx-portfolio-type">Digital Marketing </p>
                                            </div>
                                            <div className="col-lg-6 col-md-4 client">
                                            <div className='client-img'>
                                            <NavLink to="/"><ItodoImage src="images/client-logo/white/client-logo-2.png" alt=""/></NavLink>
                                            </div>
                                                <div className="sx-portfolio-btn text-right">
                                                    <NavLink to="/" title="READ MORE" className="site-button icon">
                                                        <i className="fa  fa-long-arrow-right" />
                                                        View Detail
                                                    </NavLink>
                                                    
            
                                                            
                                                            
                                                        
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="sx-portfolio-bx">
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-lg-4 col-md-4">
                                                <h4 className="sx-portfolio-title">PCMC Divyang Bhavan</h4>
                                            </div>
                                            <div className="col-lg-2 col-md-4">
                                                <p className="sx-portfolio-type">Digital Marketing / Devlopment</p>
                                            </div>
                                            <div className="col-lg-6 col-md-4 client">
                                            <div className='client-img'>
                                            <NavLink to="/"><ItodoImage src="images/client-logo/white/DivyangBhavan.png" alt=""/></NavLink>
                                            </div>
                                                <div className="sx-portfolio-btn text-right">
                                                    <NavLink to="/goverment/divyangbhavn" title="READ MORE" className="site-button icon">
                                                        <i className="fa  fa-long-arrow-right" />
                                                        View Detail
                                                    </NavLink>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="sx-portfolio-bx">
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-lg-4 col-md-4">
                                                <h4 className="sx-portfolio-title">Dr. Babasaheb Ambedkar Research and Trainging Institute</h4>
                                            </div>
                                            <div className="col-lg-2 col-md-4">
                                                <p className="sx-portfolio-type">Digital Marketing / Documentory</p>
                                            </div>
                                            <div className="col-lg-6 col-md-4 client">
                                            <div className='client-img'>
                                            <NavLink to="/"><ItodoImage src="images/client-logo/white/Barti" alt=""/></NavLink>
                                            </div>
                                                <div className="sx-portfolio-btn text-right">
                                                    <NavLink to="/goverment/divyangbhavn" title="READ MORE" className="site-button icon">
                                                        <i className="fa  fa-long-arrow-right" />
                                                        View Detail
                                                    </NavLink>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div className="sx-portfolio-bx">
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-lg-4 col-md-4">
                                                <h4 className="sx-portfolio-title">Sasun General Hospital Pune</h4>
                                            </div>
                                            <div className="col-lg-2 col-md-4">
                                                <p className="sx-portfolio-type">Operation Documentory</p>
                                            </div>
                                            <div className="col-lg-6 col-md-4 client">
                                            <div className='client-img'>
                                            <NavLink to="/"><ItodoImage src="images/client-logo/white/Sasoon" alt=""/></NavLink>
                                            </div>
                                                <div className="sx-portfolio-btn text-right">
                                                    <NavLink to="/goverment/divyangbhavn" title="READ MORE" className="site-button icon">
                                                        <i className="fa  fa-long-arrow-right" />
                                                        View Detail
                                                    </NavLink>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div className="sx-portfolio-bx">
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-lg-4 col-md-4">
                                                <h4 className="sx-portfolio-title">Persons With Disabilities Wellgfare Government of Maharashtra</h4>
                                            </div>
                                            <div className="col-lg-2 col-md-4">
                                                <p className="sx-portfolio-type">Devlopment</p>
                                            </div>
                                            <div className="col-lg-6 col-md-4 client">
                                            <div className='client-img'>
                                            <NavLink to="/"><ItodoImage src="images/client-logo/white/GovOfMah" alt=""/></NavLink>
                                            </div>
                                                <div className="sx-portfolio-btn text-right">
                                                    <NavLink to="/goverment/divyangbhavn" title="READ MORE" className="site-button icon">
                                                        <i className="fa  fa-long-arrow-right" />
                                                        View Detail
                                                    </NavLink>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div className="sx-portfolio-bx">
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-lg-4 col-md-4">
                                                <h4 className="sx-portfolio-title">Social Justis and Special Assitance</h4>
                                            </div>
                                            <div className="col-lg-2 col-md-4">
                                                <p className="sx-portfolio-type">Documentory</p>
                                            </div>
                                            <div className="col-lg-6 col-md-4 client">
                                            <div className='client-img'>
                                            <NavLink to="/"><ItodoImage src="images/client-logo/white/SJSA" alt=""/></NavLink>
                                            </div>
                                                <div className="sx-portfolio-btn text-right">
                                                    <NavLink to="/goverment/divyangbhavn" title="READ MORE" className="site-button icon">
                                                        <i className="fa  fa-long-arrow-right" />
                                                        View Detail
                                                    </NavLink>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>


                                {/* <li>
                                    <div className="sx-portfolio-bx">
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-lg-4 col-md-4">
                                                <h4 className="sx-portfolio-title">Social Justis and Special Assitance</h4>
                                            </div>
                                            <div className="col-lg-2 col-md-4">
                                                <p className="sx-portfolio-type">Digital Marketing / Devlopment</p>
                                            </div>
                                            <div className="col-lg-6 col-md-4 client">
                                            <div className='client-img'>
                                            <NavLink to="/"><ItodoImage src="images/client-logo/white/GovOfMah" alt=""/></NavLink>
                                            </div>
                                                <div className="sx-portfolio-btn text-right">
                                                    <NavLink to="/goverment/divyangbhavn" title="READ MORE" className="site-button icon">
                                                        <i className="fa  fa-long-arrow-right" />
                                                        View Detail
                                                    </NavLink>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>


                                <li>
                                    <div className="sx-portfolio-bx">
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-lg-4 col-md-4">
                                                <h4 className="sx-portfolio-title">Social Justis and Special Assitance</h4>
                                            </div>
                                            <div className="col-lg-2 col-md-4">
                                                <p className="sx-portfolio-type">Digital Marketing / Devlopment</p>
                                            </div>
                                            <div className="col-lg-6 col-md-4 client">
                                            <div className='client-img'>
                                            <NavLink to="/"><ItodoImage src="images/client-logo/white/DivyangBhavan.png" alt=""/></NavLink>
                                            </div>
                                                <div className="sx-portfolio-btn text-right">
                                                    <NavLink to="/goverment/divyangbhavn" title="READ MORE" className="site-button icon">
                                                        <i className="fa  fa-long-arrow-right" />
                                                        View Detail
                                                    </NavLink>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>


                                <li>
                                    <div className="sx-portfolio-bx">
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-lg-4 col-md-4">
                                                <h4 className="sx-portfolio-title">Social Justis and Special Assitance</h4>
                                            </div>
                                            <div className="col-lg-2 col-md-4">
                                                <p className="sx-portfolio-type">Digital Marketing / Devlopment</p>
                                            </div>
                                            <div className="col-lg-6 col-md-4 client">
                                            <div className='client-img'>
                                            <NavLink to="/"><ItodoImage src="images/client-logo/white/DivyangBhavan.png" alt=""/></NavLink>
                                            </div>
                                                <div className="sx-portfolio-btn text-right">
                                                    <NavLink to="/goverment/divyangbhavn" title="READ MORE" className="site-button icon">
                                                        <i className="fa  fa-long-arrow-right" />
                                                        View Detail
                                                    </NavLink>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li> */}


                                
                                 
                            </ul>
                            {/* <div className="sx-btn-center text-center">
                                <NavLink to="/goverment/divyangbhavn" className="site-button sx-btn-primary icon sx-btn-lg">
                                    <i className="fa  fa-long-arrow-right" />
                                    View All
                                </NavLink>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default Govermentportfolio