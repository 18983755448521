

import React from "react";
import TeamCard from "./TeamCard";

const Karan = () => {
  const KaranDetails = {
    name: "Karan Dhole",
    designation: "Web Developer",
    bloodGroup: "O+",
    profilePic: "images/our-team5/Karan.jpg",
    contact: {
      employeeId: "0015",
      phone: "+91 9021568402",
      email: "karandhole123@gmail.com",
      locationUrl: "https://maps.app.goo.gl/uZSwkEKtCsXLKEuM9",
      emergencyPhone: "+91 9021568402",
      companyMail: "karan@azown.in" ,
    },
    website: "https://azown.in",
    address: "Vidya Vally Road Susgaon Pune",
    socialLinks: {
      facebook: "https://www.facebook.com",
      instagram: "https://www.instagram.com",
      twitter: "https://www.twitter.com",
    },
    description:
      "A dedicated web developer with a passion for creating dynamic and responsive web applications. Always eager to learn and adapt to new technologies.",
  };

  return <TeamCard {...KaranDetails} />;
};

export default Karan;

