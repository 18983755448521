import React, { useState } from "react";
import ItodoImage from "../../elements/itodo-img"; // Assuming ItodoImage handles image rendering
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileAlt, faAt, faHome, faMapPin } from "@fortawesome/free-solid-svg-icons";
import { FaMobileAlt, FaAt, FaHome, FaMapPin } from "react-icons/fa"; // Updated Icons
import { faFacebook, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons"; // Social Media Icons
import { FaSquareXTwitter, FaSquareFacebook, FaSquareInstagram, FaUserTie } from "react-icons/fa6";
import { MdEmail, MdCall, MdComputer, MdAddLocationAlt } from "react-icons/md";
import "./Info.css"; // Additional custom styles

const TeamCard =({
  name,
  designation,
  bloodGroup,
  profilePic,
  contact: { employeeId, phone, emergencyPhone, email, companyMail},
  website,
  address,
  socialLinks: { facebook, instagram, twitter },
  description,
}) => {
  const [isFlipped, setIsFlipped] = useState(false); // State to manage flip

  const handleCardClick = () => {
    setIsFlipped(!isFlipped); // Toggle flip state
  };

  return (
    <div className="info-card" onClick={handleCardClick}>
      <div className={`card-inner ${isFlipped ? 'flipped' : ''}`}>
        {/* Front Side */}
        <div className="card-front">
          {/* Logo Section */}
          <div className="logo-div">
            <ItodoImage src="images/Logo.png" alt="Company Logo" />
          </div>

          {/* Profile Picture */}
          <div className="profile">
            <ItodoImage src={profilePic} alt="Profile Picture" />
          </div>

          {/* Name and Designation */}
          <h2 className="name">{name}</h2>
          <h4 className="dev">{designation}</h4>
          <p className="blood-g">Blood Group : {bloodGroup}</p>

          {/* Contact Details */}
          <div className="contact-details">
            <p>
            <FaUserTie />
            <strong>{employeeId}</strong>
            </p>
            <p>
            <MdCall />
              <a href={`tel:${emergencyPhone}`}>{emergencyPhone}</a>
            </p>
            <p>
            <MdEmail />
              <a href={`mailto:${email}`}>{email}</a>
            </p>
            <p>
            <MdComputer />
              <a
                href={website}
                target="_blank"
                rel="noopener noreferrer"
              >
                {website}
              </a>
            </p>
            <p className="address">
            <MdAddLocationAlt />
               <a> {address}</a>
            </p>
          </div>

          {/* Social Media Links */}
          <div className="tags">
            <a
              href={facebook}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Visit our Facebook page"
            >
              <FaSquareFacebook />
            </a>
            <a
              href={instagram}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Visit our Instagram page"
            >
              <FaSquareInstagram />
            </a>
            <a
              href={twitter}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Visit our Twitter page"
            >
              <FaSquareXTwitter />
            </a>
          </div>
        </div>

        {/* Back Side */}
        <div className="card-back">
          <h2>Details</h2>

          <div className="contact-details">
            <p>
            <MdCall />
              <a href={`tel:${phone}`}>{phone}</a>
            </p>
            <p>
            <MdEmail />
              <a href={`mailto:${companyMail}`}>{companyMail}</a>
            </p>
            <p>
            <MdComputer />
              <a
                href={website}
                target="_blank"
                rel="noopener noreferrer"
              >
                {website}
              </a>
            </p>
            <p className="address">
            <MdAddLocationAlt />
              <a
                href="https://maps.app.goo.gl/uZSwkEKtCsXLKEuM9"
                target="_blank"
                rel="noopener noreferrer"
              >
                Azown Technology Pvt. Ltd. Pune
              </a>
            </p>
            
          </div>
          <p className="emp-description">
            <strong className="des-head">Description:</strong> {description}
          </p>
          

        </div>
      </div>
    </div> 
  );
}

export default TeamCard;