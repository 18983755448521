



import React from "react";
import TeamCard from "./TeamCard";

const Khushbu = () => {
  const KhushbuDetails = {
    name: "Khushbu Keshari",
    designation: "Marketing Manager",
    bloodGroup: "A+", // Blood group not mentioned
    profilePic: "images/our-team5/Khushbu.jpg",
    contact: {
      employeeId: "007", // Employee ID not mentioned
      phone: "+916204916091",
      emergencyPhone: "+91 6204916091",
      email: "khushbu@azown.in",
      companyMail: "khushbu@azown.in",
      locationUrl: "https://maps.app.goo.gl/uZSwkEKtCsXLKEuM9",
    },
    website: "https://azown.in",
    address: "Bhumkar Chowk , Pune",
    socialLinks: {
      facebook: "https://www.facebook.com",
      instagram: "https://www.instagram.com",
      twitter: "https://www.twitter.com",
    },
    description: "Khushbu Keshari is a skilled Marketing Manager at Azown Technology Pvt. Ltd., bringing creativity and innovation to every project.",
  };

  return <TeamCard {...KhushbuDetails} />;
};

export default Khushbu;







