import React from 'react';
import ItodoImage from "../../../elements/itodo-img";
import { NavLink } from "react-router-dom";
import Slider from "react-slick"; // Import Slick Slider

function SectionClients1() {
    // Slick slider settings
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 2,
        autoplay: true, // Enable autoplay
        autoplaySpeed: 3000, // Duration for each slide (in milliseconds)
        pauseOnHover: true, // Pause autoplay on hover
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: true,
                },
            },
        ],
    };

    return (
        <div className="section-full p-t110 p-b80 sx-client-logo-2-outer">
            <div className="container">
                <div className="section-content">
                <div className="section-head center max-900">
                            <div className="sx-head-s-title">Our Clients</div>
                            <div className="sx-head-l-title">
                                <h2 className="sx-title2">Our 120 happy users around worldwide.</h2>
                            </div>
                        </div>
                    <div className="client-logo-pic2-wrap">
                        <Slider {...settings}>
                            <div>
                                <NavLink to="/contact-us" className="client-logo-pic2">
                                    <ItodoImage src="images/client-logo/white/client-logo-1.png" alt="" />
                                </NavLink>
                            </div>
                            <div>
                                <NavLink to="/contact-us" className="client-logo-pic2">
                                    <ItodoImage src="images/client-logo/white/client-logo-2.png" alt="" />
                                </NavLink>
                            </div>
                            <div>
                                <NavLink to="/contact-us" className="client-logo-pic2">
                                    <ItodoImage src="images/client-logo/white/client-logo-3.png" alt="" />
                                </NavLink>
                            </div>
                            <div>
                                <NavLink to="/contact-us" className="client-logo-pic2">
                                    <ItodoImage src="images/client-logo/white/client-logo-4.png" alt="" />
                                </NavLink>
                            </div>
                            <div>
                                <NavLink to="/contact-us" className="client-logo-pic2">
                                    <ItodoImage src="images/client-logo/white/client-logo-5.png" alt="" />
                                </NavLink>
                            </div>
                            <div>
                                <NavLink to="/contact-us" className="client-logo-pic2">
                                    <ItodoImage src="images/client-logo/white/client-logo-6.png" alt="" />
                                </NavLink>
                            </div>
                            <div>
                                <NavLink to="/contact-us" className="client-logo-pic2">
                                    <ItodoImage src="images/client-logo/white/client-logo-7.png" alt="" />
                                </NavLink>
                            </div>
                            <div>
                                <NavLink to="/contact-us" className="client-logo-pic2">
                                    <ItodoImage src="images/client-logo/white/client-logo-8.png" alt="" />
                                </NavLink>
                            </div>
                            <div>
                                <NavLink to="/contact-us" className="client-logo-pic2">
                                    <ItodoImage src="images/client-logo/white/client-logo-9.png" alt="" />
                                </NavLink>
                            </div>
                            <div>
                                <NavLink to="/contact-us" className="client-logo-pic2">
                                    <ItodoImage src="images/client-logo/white/client-logo-10.png" alt="" />
                                </NavLink>
                            </div>
                            <div>
                                <NavLink to="/contact-us" className="client-logo-pic2">
                                    <ItodoImage src="images/client-logo/white/client-logo-11.png" alt="" />
                                </NavLink>
                            </div>
                            <div>
                                <NavLink to="/contact-us" className="client-logo-pic2">
                                    <ItodoImage src="images/client-logo/white/client-logo-12.png" alt="" />
                                </NavLink>
                            </div>
                            <div>
                                <NavLink to="/contact-us" className="client-logo-pic2">
                                    <ItodoImage src="images/client-logo/white/client-logo-13.png" alt="" />
                                </NavLink>
                            </div>
                            <div>
                                <NavLink to="/contact-us" className="client-logo-pic2">
                                    <ItodoImage src="images/client-logo/white/DivyangBhavan.png" alt="" />
                                </NavLink>
                            </div>
                            <div>
                                <NavLink to="/contact-us" className="client-logo-pic2">
                                    <ItodoImage src="images/client-logo/white/SandhyaNews.png" alt="" />
                                </NavLink>
                            </div>
                             <div>
                                <NavLink to="/contact-us" className="client-logo-pic2">
                                    <ItodoImage src="images/client-logo/white/Ganeshpeth.png" alt="" />
                                </NavLink>
                            </div>
                            
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SectionClients1;
