import React from "react";
import TeamCard from "./TeamCard";

const Chetan = () => {
  const ChetanDetails = {
    name: "Chetan Khote",
    designation: "Graphic Designer/Video Editor",
    bloodGroup: "B+", // Blood group not mentioned
    profilePic: "images/our-team5/Chetan.jpg",
    contact: {
      employeeId: "", // Employee ID not mentioned
      phone: "+91 9765618465",
      emergencyPhone: "+91 9765618465", // Emergency phone number
      email: "chetan@azown.in",
      companyMail: "chetan@azown.in", // Company mail
      locationUrl: "https://maps.app.goo.gl/uZSwkEKtCsXLKEuM9",
    },
    website: "https://azown.in",
    address: "Mhasoba Chowk,Dattawadi,pune",
    socialLinks: {
      facebook: "https://www.facebook.com",
      instagram: "https://www.instagram.com",
      twitter: "https://www.twitter.com",
    },
    description: "Chetan Khote is a talented Graphic Designer and Video Editor at Azown Technology Pvt. Ltd., known for creating visually compelling content and delivering quality video projects.",
  };
  
  

  return <TeamCard {...ChetanDetails} />;
};

export default Chetan;
