import React from 'react'
import { bannerData } from "../../../globals/banner";
import Banner from "../../sections/common/banner";
import Govermentportfolio from '../../sections/Goverment/Govermentportfolio';

const Goverment = () => {
  return (
  <>
    <Banner _data={bannerData.portfolio2} />
    <Govermentportfolio />
    </>
  )
}

export default Goverment