import { NavLink , useLocation } from "react-router-dom";
import ItodoImage from "../../elements/itodo-img";
import { useState } from "react";
import { loadScript } from "../../../globals/constants";
import { useEffect } from "react";

function Header1() {

    const [isActive, setIsActive] = useState(false);
    const location = useLocation();
    function toggleNavClass() {
        setIsActive(!isActive)
    }

    useEffect(()=>{
        loadScript("js/mobilenav.js")
    })
    const isActiveRoute = (path) => location.pathname === path;

    return (
        <>
            <header className={"header-style1 site-header  mobile-sider-drawer-menu " + (isActive ? "active" : "")}>
                {/* <div className="top-bar">
                    <div className="container">
                        <div className="d-flex justify-content-between  align-content-center">
                            <div className="sx-topbar-left">
                                <ul className="e-p-bx">
                                    <li>azown1@gmail.com</li>
                                    <li><a href="tel:9112299435">+91 9112299435 call</a></li>
                                </ul>
                            </div>
                            <div className="sx-topbar-right">
                                <div className="top-bar-nav">
                                    <ul>
                                        <li><NavLink to="/about-us">Terms &amp; Condition</NavLink></li>
                                        <li><NavLink to="/faq">Privacy Policy</NavLink></li>
                                        <li><NavLink to="/contact-us">Contact Us</NavLink></li>
                                    </ul>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="sticky-header main-bar-wraper navbar-expand-lg">
                    <div className="main-bar">
                        <div className="container clearfix">
                            <div className="logo-header">
                                <div className="logo-header-inner logo-header-one">
                                    <NavLink to="/">
                                        <ItodoImage src="images/Logo.png" alt="" />
                                    </NavLink>
                                </div>
                            </div>
                            {/* NAV Toggle Button */}
                            <button id="mobile-side-drawer"
                                data-target=".header-nav"
                                data-toggle="collapse"
                                className="navbar-toggler collapsed"
                                onClick={toggleNavClass}
                            >
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar icon-bar-first" />
                                <span className="icon-bar icon-bar-two" />
                                <span className="icon-bar icon-bar-three" />
                            </button>
                            {/* EXTRA NAV */}
                            {/* <div className="extra-nav">
                                <div className="extra-cell">
                                    <ul className="list-unstyled social-bx d-flex flex-wrap align-content-center">
                                        <li><a href="https://www.facebook.com/"><i className="feather-facebook" /> </a></li>
                                        <li><a href="https://twitter.com/"><i className="feather-twitter" /> </a></li>
                                        <li><a href="https://www.linkedin.com/"><i className="feather-linkedin" /> </a></li>
                                        <li><a href="https://www.instagram.com/"><i className="feather-instagram" /> </a></li>
                                    </ul>
                                </div>
                            </div> */}
                            {/* EXTRA Nav */}
                            {/* MAIN NAVIGATION */}
                            <div className="header-nav navbar-collapse collapse d-flex justify-content-center collapse ">
                                <ul className=" nav navbar-nav ">
                                    {/* home */}
                                    <li className={isActiveRoute("/") ? "active" : ""}>
                                        <NavLink to="/">Home</NavLink>
                                        {/* <ul className="sub-menu">
                                            <li><NavLink to="/index">Home-1</NavLink></li>
                                            <li><NavLink to="/index2">Home-2</NavLink></li>
                                            <li><NavLink to="/index3">Home-3</NavLink></li>
                                        </ul> */}
                                    </li>
                                     {/* Company */}
                                    <li className={isActiveRoute("/about-us") ? "active" : ""}>
                                        <NavLink to="/team">Company</NavLink>
                                        <ul className="sub-menu">
                                            <li><NavLink to="/about-us">About Azown</NavLink></li>
                                            {/* <li><NavLink to="/faq">Faq Page</NavLink></li>
                                            <li><NavLink to="/gallery">Gallery</NavLink></li>
                                            <li><NavLink to="/icons">Icons</NavLink></li> */}
                                             <li><NavLink to="/team">Team</NavLink></li>
                                             <li><NavLink to="/">Blogs</NavLink></li>
                                            {/* <li><NavLink to="/">Testimonials</NavLink></li> */}
                                            {/* <li><NavLink to="/why-choose-us">Why Choose Us</NavLink></li>
                                            <li><NavLink to="/pricing">Pricing</NavLink></li> */}
                                             {/* <li><NavLink to="/portfolio">Portfolio</NavLink></li>  */}
                                           
                                        </ul>
                                    </li>
                                     {/* About Azown */}
                                     {/* <li className={isActiveRoute("/about-us") ? "active" : ""} >
                                        <NavLink to="/about-us">About Us</NavLink>
                                    </li> */}
                                     {/* Government */}
                                    <li className={isActiveRoute("/goverment") ? "active" : ""}>
                                        <NavLink to="/government">Government</NavLink>
                                    </li>
                                     {/* Services */}
                                    <li className={isActiveRoute("/services") ? "active" : ""}>
                                        <NavLink to="/services">Services</NavLink>
                                        <ul className="sub-menu">
                                            {/* <li><NavLink to="/mission">Mission &amp; Vision</NavLink></li>
                                            <li><NavLink to="/services">Services</NavLink></li>
                                            <li><NavLink to="/services/detail">Services Detail</NavLink></li>
                                            <li><NavLink to="/portfolio">Portfolio</NavLink></li>
                                            <li><NavLink to="/portfolio2">Portfolio-2</NavLink></li>
                                            <li><NavLink to="/portfolio/detail">Portfolio Detail</NavLink></li> */}
                                            <li><NavLink to="/services">All Services</NavLink></li>
                                            <li><NavLink to="/web-devlopment">Web Devlopment</NavLink></li>
                                            <li><NavLink to="/digital-marketing">Digital Marketing</NavLink></li>
                                            <li><NavLink to="/graphic-design">Graphic Design</NavLink></li>
                                            <li><NavLink to="/video-production">Video Production</NavLink></li>
                                            <li><NavLink to="/content-creation">Content Creation</NavLink></li>
                                            <li><NavLink to="/e-commerce-solution">E-Commerce Solution</NavLink></li>
                                            
                                        </ul>
                                    </li>
                                    
                                     {/* Industries */}
                                    <li className={isActiveRoute("/industries") ? "active" : ""}>
                                        <NavLink to="/">Industries</NavLink>
                                        <ul className="sub-menu">
                                            {/* <li><NavLink to="/mission">Mission &amp; Vision</NavLink></li>
                                            <li><NavLink to="/services">Services</NavLink></li>
                                            <li><NavLink to="/services/detail">Services Detail</NavLink></li>
                                            <li><NavLink to="/portfolio">Portfolio</NavLink></li>
                                            <li><NavLink to="/portfolio2">Portfolio-2</NavLink></li>
                                            <li><NavLink to="/portfolio/detail">Portfolio Detail</NavLink></li> */}
                                            {/* <li><NavLink to="/entertenment-digital-marketing">Entertainment</NavLink></li> */}
                                            {/* <li><NavLink to="/insurance-digital-marketing">Insurance </NavLink></li> */}
                                            <li><NavLink to="/real-estate-digital-marketing">Real Estate </NavLink></li>
                                            <li><NavLink to="/automobile-digital-marketing">Automobile</NavLink></li>
                                             <li><NavLink to="/healthcare-digital-marketing">Healthcare </NavLink></li>
                                            <li><NavLink to="/itsolutions-marketing">IT Solutions</NavLink></li>
                                            {/* <li><NavLink to="/agriculture-digital-marketing">Agriculture</NavLink></li> */}
                                            
                                        </ul>
                                    </li>
                                    {/* <li className={isActiveRoute("/blogs") ? "active" : ""}>
                                        <NavLink to="/">Blogs</NavLink>
                                        
                                    </li> */}
                                    <li className={isActiveRoute("/contact-us") ? "active" : ""}><NavLink to="/contact-us">Contact Us</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header >

        </>
    )
}

export default Header1;