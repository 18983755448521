



import React from "react";
import TeamCard from "./TeamCard";

const Samarth = () => {
  const SamarthDetails = {
    name: "Samarth Narawne",
    designation: "Video Editor / Photographer",
    bloodGroup: "AB+", // Blood group not mentioned
    profilePic: "images/our-team5/Samarth.jpg",
    contact: {
      employeeId: "007", // Employee ID not mentioned
      phone: "+918766459943",
      emergencyPhone: "+91 8766459943",
      email: "samarth@azown.in",
      companyMail: "samarth@azown.in",
      locationUrl: "https://maps.app.goo.gl/uZSwkEKtCsXLKEuM9",
    },
    website: "https://azown.in",
    address: "neela villa, near bhakti park housing society, manaji nagar, narhe, pune 411041",
    socialLinks: {
      facebook: "https://www.facebook.com",
      instagram: "https://www.instagram.com",
      twitter: "https://www.twitter.com",
    },
    description: "Samarth Narawne is a skilled Video Editor at Azown Technology Pvt. Ltd., bringing creativity and innovation to every project.",
  };

  return <TeamCard {...SamarthDetails} />;
};

export default Samarth;





