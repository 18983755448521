// import React from "react";
// import ItodoImage from "../../elements/itodo-img"; // Assuming ItodoImage handles image rendering
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faMobileAlt, faAt, faHome, faMapPin } from "@fortawesome/free-solid-svg-icons"; // Updated Icons
// import "./Info.css"; // Additional custom styles

// const Venkateshchavan = () => {
//   return (
//     <div className="info-card">
//       {/* Logo Section */}
//       <div className="logo-div">
//         <ItodoImage src="images/Logo.png" alt="Company Logo" />
//       </div>

//       {/* Profile Picture */}
//       <div className="profile">
//         <ItodoImage src="images/our-team5/venkatesh.jpg" alt="Profile Picture" />
//       </div>

//       {/* Name and Designation */}
//       <h2 className="name">Venkatesh Chavan</h2>
//       <p className="dev">Founder & Director</p>

//       {/* Contact Details */}
//       <div className="contact-details">
//         <p>
//           <FontAwesomeIcon icon={faMobileAlt} />
//           <a href="tel:+919112299435">+91 9112299435</a>
//         </p>
//         <p>
//           <FontAwesomeIcon icon={faAt} />
//           <a href="mailto:azown1@gmail.com">azown1@gmail.com</a>
//         </p>
//         <p>
//           <FontAwesomeIcon icon={faHome} />
//           <a
//             href="https://azown.in"
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             azown.in
//           </a>
//         </p>
//         <p className="address">
//           <FontAwesomeIcon icon={faMapPin} />
//           <a
//             href="https://maps.app.goo.gl/uZSwkEKtCsXLKEuM9"
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             Azown Technology Pvt. Ltd. Pune
//           </a>
//         </p>
//       </div>

//       {/* Social Media Links */}
//       <div className="tags">
//   {/* Facebook */}
//   <a
//     href="https://www.facebook.com"
//     target="_blank"
//     rel="noopener noreferrer"
//     aria-label="Visit our Facebook page"
//   >
//     <i className="fa fa-facebook"></i>
//   </a>

//   {/* Instagram */}
//   <a
//     href="https://www.instagram.com"
//     target="_blank"
//     rel="noopener noreferrer"
//     aria-label="Visit our Instagram page"
//   >
//     <i className="fa fa-instagram"></i>
//   </a>

//   {/* Twitter */}
//   <a
//     href="https://www.twitter.com"
//     target="_blank"
//     rel="noopener noreferrer"
//     aria-label="Visit our Twitter page"
//   >
//     <i className="fa fa-twitter"></i>
//   </a>
// </div>

//     </div>
//   );
// };

// export default Venkateshchavan;




import React from "react";
import TeamCard from "./TeamCard";

const Venkatesh = () => {
  const VenkateshDetails = {
    name: "Venkatesh Chavhan",
    designation: "Director",
    bloodGroup: "A+", // Blood group not mentioned
    profilePic: "images/our-team5/venkatesh.jpg",
    contact: {
      employeeId: "001", // Employee ID not mentioned
      phone: "+919112299435",
      emergencyPhone: "+91 9112299435",
      email: "venkatesh@azown.in",
      companyMail: "venkatesh@azown.in",
      locationUrl: "https://maps.app.goo.gl/uZSwkEKtCsXLKEuM9",
    },
    website: "https://azown.in",
    address: "Mangal Bhairav, Nanded City, Pune",
    socialLinks: {
      facebook: "https://www.facebook.com",
      instagram: "https://www.instagram.com",
      twitter: "https://www.twitter.com",
    },
    description: "Venkatesh Chavhan.",
  };

  return <TeamCard {...VenkateshDetails} />;
};

export default Venkatesh;









