import React from 'react'
import Banner from '../../sections/common/banner'
import { bannerData } from "../../../globals/banner";
import SectionAboutCompany4 from '../../sections/IT solutions/mission-vision/section-about-company4';

const Portfolio = () => {
  return (
    <div>
      <Banner _data={bannerData.mission} />
      <SectionAboutCompany4/>
      
    </div>
  )
}

export default Portfolio
