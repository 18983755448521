





import React from "react";
import TeamCard from "./TeamCard";

const Shreya = () => {
  const ShreyaDetails = {
    name: "Shreya Thakur",
    designation: "Social Media Executive",
    bloodGroup: "O+", // Blood group not mentioned
    profilePic: "images/our-team5/Shreya.jpg",
    contact: {
      employeeId: "0015", // Employee ID not mentioned
      phone: "+918839810993",
      emergencyPhone: "+91 8839810993",
      email: "shreya@azown.in",
      companyMail: "shreya@azown.in",
      locationUrl: "https://maps.app.goo.gl/uZSwkEKtCsXLKEuM9",
    },
    website: "https://azown.in",
    address: "Flat no. 1906, B1B2 Yashone infinitee, Punawale, Pune.",
    socialLinks: {
      facebook: "https://www.facebook.com",
      instagram: "https://www.instagram.com",
      twitter: "https://www.twitter.com",
    },
    description: "Shreya Thakur is a skilled Social Media Executive at Azown Technology Pvt. Ltd., bringing creativity and innovation to every project.",
  };

  return <TeamCard {...ShreyaDetails} />;
};

export default Shreya;






